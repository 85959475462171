// Request a call (Email)
document.addEventListener('DOMContentLoaded', function() {
    var links = document.querySelectorAll('a[data-modal-type=request-info]');

    // Anonymous form
    links.forEach(function(link) {
        link.addEventListener('click', function(e) {
            e.preventDefault();

            var modalTaxonomy = this.getAttribute('data-modal-taxonomy');
            
            switch (modalTaxonomy) {
                case "insurance":
                    location.href = "/support-contact/request-a-call-insurance";
                    break;
                case "fia":
                    location.href = "/support-contact/request-a-call-investment-advisors";
                    break;
                case "ira":
                    location.href = "/support-contact/request-a-call-ira";
                    break;
                case "general":
                default:
                    location.href = "/support-contact/request-a-call";
                    break;
            }
        });
    });
});