import { Modal }  from 'bootstrap'; 

//intercept anchor clicks and display third party modal
const daoModals = document.querySelectorAll('a[data-modal-type="dao"]');

for (let i = 0; i < daoModals.length; i++) {
  daoModals[i].addEventListener("click", function (event) {
    event.preventDefault(); // Prevent default anchor tag behavior
    createDaoModal(window, daoModals[i].href); // Call the modal creation function
  });
}

  // dao modal
window.createDaoModal = function callModal(window, link) {
  var daoModal = new Modal(document.getElementById("dao-modal"));
  Modal.getInstance(document.getElementById("dao-modal")).show();
  document.getElementById("dao-modal-sign-up").href = link;
}