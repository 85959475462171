var topNavExists = document.getElementById("mobile-nav");

if (topNavExists != null || topNavExists != undefined) {
    
    document.addEventListener("DOMContentLoaded", function() {
        document.getElementById("mobile-menu-toggle").addEventListener("click", function() {
            document.getElementById("mobile-nav-menu").classList.toggle("show");
        });

        document.getElementById("mobile-menu-close").addEventListener("click", function() {
            document.getElementById("mobile-nav-menu").classList.toggle("show");
        });

        var page = window.location.pathname;
        switch (page) {
            case '/personal':
                document.getElementById("desktop-nav-personal-link").classList.add("active");
                break;
            case '/business':
                document.getElementById("desktop-nav-business-link").classList.add("active");
                break;
            case '/why-frost':
                document.getElementById("desktop-nav-why-frost-link").classList.add("active");
                break;
            default:
                break;
        }

        var clickIsFromHover = false;

        // Personal Top Nav Dropdown
        var topNavPersonalDropdownToggle = document.getElementById("desktop-nav-personal-link");
        var topNavPersonalDropdownMenu = document.getElementById("top-nav-personal-menu");
        topNavPersonalDropdownToggle.onmouseenter = function () {
            clickIsFromHover = true;
            this.click();
            document.activeElement.blur();
            clickIsFromHover = false;

        };
        topNavPersonalDropdownToggle.onclick = function () {
            if (!clickIsFromHover && window.screen.width > 768) {
                window.location = "/personal";
            }
            if (window.screen.width <= 768) {
                this.click();
            }
        };
        topNavPersonalDropdownMenu.onmouseleave = function () {
            this.click()
            clickIsFromHover = false;
        };

        // Business Top Nav Dropdown
        var topNavBusinessDropdownToggle = document.getElementById("desktop-nav-business-link");
        var topNavBusinessDropdownMenu = document.getElementById("top-nav-business-menu");
        topNavBusinessDropdownToggle.onmouseenter = function () {
            clickIsFromHover = true;
            this.click();
            document.activeElement.blur();
            clickIsFromHover = false;
        };
        topNavBusinessDropdownToggle.onclick = function () {
            if (!clickIsFromHover && window.screen.width > 768) {
                window.location = "/business";
            }
            if (window.screen.width <= 768) {
                this.click();
            }
        };
        topNavBusinessDropdownMenu.onmouseleave = function () {
            this.click()
            clickIsFromHover = false;
        };

        // Why Frost Top Nav Dropdown
        var topNavWhyFrostDropdownToggle = document.getElementById("desktop-nav-why-frost-link");
        var topNavWhyFrostDropdownMenu = document.getElementById("top-nav-why-frost-menu");
        topNavWhyFrostDropdownToggle.onmouseenter = function () {
            clickIsFromHover = true;
            this.click();
            document.activeElement.blur();
            clickIsFromHover = false;
        };
        topNavWhyFrostDropdownToggle.onclick = function () {
            if (window.screen.width <= 768) {
                this.click();
            }
        };
        topNavWhyFrostDropdownMenu.onmouseleave = function () {
            this.click()
            clickIsFromHover = false;
        };

        var nonDropdownLinks = document.querySelectorAll(".top-nav .nav-link:not(.dropdown-toggle)");
        function closeAllDropdownMenus() {
            topNavWhyFrostDropdownMenu.click();
            topNavBusinessDropdownMenu.click();
            topNavPersonalDropdownMenu.click();
        }
        nonDropdownLinks.forEach(function (nonDropdownLinks) {
            nonDropdownLinks.addEventListener("mouseover", closeAllDropdownMenus);
        });

        function setMegaMenuPosititon() {
            var navHeight = document.querySelector(".legacy-nav").offsetHeight;
            document.querySelectorAll('.dropdown-menu').forEach(function(menu) {
                menu.style.transform = 'translateY(' + navHeight + 'px)';
            });
            var topNavHeight = document.querySelector('top-nav').offsetHeight;
            var bannerHeight = document.querySelector('banner').offsetHeight;
            document.querySelector('.dropdown-menu').style.transform = "translate3d(0px, " + (topNavHeight + bannerHeight) + "px, 0px) !important";
        }
    });
}
else {
    console.log("It does not exist!");
}
