import { Modal } from 'bootstrap';

// Intercept anchor clicks and display third party modal
document.querySelectorAll('a[data-modal-type=flatModal]').forEach(flatLink => {
  flatLink.addEventListener('click', event => {
    event.preventDefault();
    createFlatModal(window, event.target.getAttribute('href'));
  })
});

// dao modal
window.createFlatModal = function callModal(window, link) {
  var daoModal = new Modal(document.getElementById("flatModal"));
  Modal.getInstance(document.getElementById("flatModal")).show();
  document.getElementById("acceptFlatModal").href = link;
}