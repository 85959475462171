const controls = document.querySelectorAll(".video-player_controls");
controls.forEach(control => {
    const video = control.previousSibling.previousSibling

    // In case video started before JS can be run
    if (!video.paused) {
        control.querySelector("#play-button").style.display = "none";
        control.querySelector("#pause-button").style.display = "block";
    } else {
        control.querySelector("#play-button").style.display = "block";
        control.querySelector("#pause-button").style.display = "none";
    }

    video.addEventListener("play", () => {
        control.querySelector("#play-button").style.display = "none";
        control.querySelector("#pause-button").style.display = "block";
    });

    video.addEventListener("pause", () => {
        control.querySelector("#play-button").style.display = "block";
        control.querySelector("#pause-button").style.display = "none";
    });

    control.querySelector("#play-button").addEventListener("click", () => {
        video.play();
    });

    control.querySelector("#pause-button").addEventListener("click", () => {
        video.pause();
    });


});