// import function to register Swiper custom elements and register Swiper custom elements
import { register } from 'swiper-latest/element/bundle';
register();

//If you're looking at docs, remember we renamed our swiper to swiper-latest
import Swiper from 'swiper-latest';
import { Navigation, Pagination, A11y, Thumbs } from 'swiper-latest/modules';

Swiper.use([Navigation, Pagination, A11y, Thumbs]);

var carousels = document.getElementsByClassName("swiper-container");

Array.from(carousels).forEach((element) => {
    var looping = element.dataset.looping == 'true' ?? false;
    var spaceBetween = element.dataset.spaceBetween ?? 16;
    var desktopSpaceBetween = element.dataset.desktopSpaceBetween ?? 49;
    var centeredSlides = element.dataset.centeredSlides == 'true' ?? true;
    var slidesPerView = element.dataset.slidesPerView ?? 1;
    var slidesPerViewMobile = element.dataset.slidesPerViewMobile ?? 1;
    var grabCursor = element.dataset.grabCursor == 'true' ?? true;
    var pagination = element.dataset.pagination == 'true' ?? true;
    var navigation = element.dataset.navigation == 'true' ?? true;
    var thumbs = element.dataset.thumbs == 'true' ?? true;
    var speed = element.dataset.speed ?? 300;
    var id = element.dataset.id ?? 1;
    const prevControllerClass = ".swiper-button-prev-"+id;
    const nextControllerClass = ".swiper-button-next-"+id;

    var swiperConfig = {
        keyboard: {
            enabled: true,
        },
        breakpoints: {
            0: {
                slidesPerView: slidesPerViewMobile,
                spaceBetween: spaceBetween
            },
            768: {
                slidesPerView: slidesPerView,
                spaceBetween: desktopSpaceBetween,
            }
        }
    }

    swiperConfig.centeredSlides = centeredSlides;
    swiperConfig.grabCursor = grabCursor;
    swiperConfig.loop = looping;
    swiperConfig.speed = speed;

    if (pagination) {
        swiperConfig.pagination = {
            el: ".swiper-pagination",
            clickable: true
        }
    }

    if (navigation) {
        swiperConfig.navigation = {
            prevEl: prevControllerClass,
            nextEl: nextControllerClass
        }
    }

    if (thumbs) {
        swiperConfig.thumbs = {
            loop: true
        }
    }

    var swiper = new Swiper(element, swiperConfig);
    
});