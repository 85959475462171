/** Import React */
// import React, { Component } from 'react'
// import ReactDOM from 'react-dom'

/** import fontawesome */
// Improve this in future import { library, dom } from "@fortawesome/fontawesome-svg-core";
// import '@fortawesome/fontawesome-pro/js/all';

/** import scss */
import '/src/aurora/scss/themes/borealis/borealis.scss';

/** Bootstrap imports */
import '/src/aurora/js/libs/bootstrap.js';

//** Swiper Borealis import */
import '/src/aurora/js/libs/carousels-borealis.js';

//** media-chrome video player */
import 'media-chrome';

/** Our JS **/
import '/src/aurora/js/modals/offsite.js';
import '/src/aurora/js/modals/daoModal.js';
import '/src/aurora/js/modals/migration.js';
import '/src/aurora/js/modals/flatModal.js'
import '/src/aurora/js/_patterns/01-molecules/accordion.js';
//import '/src/aurora/js/_patterns/01-molecules/carousel-borealis.js';
import '/src/aurora/js/_patterns/01-molecules/flippy-tile.js';

import '/src/aurora/js/_patterns/02-organisms/accounts.js';
import '/src/aurora/js/_patterns/02-organisms/darkmode.js';
import '/src/aurora/js/_patterns/02-organisms/personal-checking-hero.js';
import '/src/aurora/js/_patterns/02-organisms/top-nav.js';
import '/src/aurora/js/_patterns/02-organisms/video.js';