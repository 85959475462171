/**
 * Import just the bootstrap we will use
 * 
 * Exposes bootstrap to window to be used in the frontend
 * Can be used with bootstrap.{Component}
 * 
 */

import { Alert, Dropdown, Tab, Toast, Tooltip, Popover, Modal, Collapse } from 'bootstrap'; 

window.Alert = Alert;
window.Dropdown = Dropdown;
window.Tab = Tab;
window.Tooltip = Tooltip;
window.Toast = Toast;
window.Popover = Popover;
window.Modal = Modal;
window.Collapse = Collapse;