//window.accordionOpen = false;
function toggleAccordion() {
  var viewBtn = document.getElementById("viewAllAccordion");
  var collapseElementList = [].slice.call(document.querySelectorAll('.accordion-collapse'));
  var accordionButtons = [].slice.call(document.querySelectorAll('.accordion-button'));
  var viewAllAccordionsButton = document.getElementById("viewAllAccordion");
  if (viewAllAccordionsButton.innerText == "Expand all") {
    var collapseList = collapseElementList.map(function (collapseEl) {
      collapseEl.classList.add('show');
      collapseEl.classList.remove('hide');
    });
    var caretCollapse = accordionButtons.map(function (collapseEl) {
      collapseEl.classList.remove('collapsed');
    });
    viewAllAccordionsButton.innerText = "Collapse all";
    viewAllAccordionsButton.classList.remove('collapsed');
    viewAllAccordionsButton.classList.add('expanded');
    //window.accordionOpen = true;
  } else {
    //If all the accordions are alreeady closed
    if((accordionButtons => Button.classList.contains("collapsed")) == accordionButtons.length){
      viewAllAcorddionsButton.classList.remove('expanded');
      viewAllAcorddionsButton.classList.add('collapsed');
      return;
    }
    var collapseList = collapseElementList.map(function (collapseEl) {
      collapseEl.classList.add('hide');
      collapseEl.classList.remove('show');
    }); 
    var caretCollapse = accordionButtons.map(function (collapseEl) {
      collapseEl.classList.add('collapsed');
      // document.getElementById("accordionHeroBar").scrollIntoView();
    });
    viewAllAccordionsButton.innerText = "Expand all";
    viewAllAccordionsButton.classList.remove('expanded');
    viewAllAccordionsButton.classList.add('collapsed');
    //window.accordionOpen = false;
  }
}

window.toggleAccordion = toggleAccordion;